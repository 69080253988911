import React from 'react';
import { Link } from 'gatsby';

const MenuItem = ({item}) => {
    return (
        <li className="main__menu__item">
            <Link to={item.to} className="main__menu__link" activeClassName="main__menu__link--active" title={item.title}>{item.title}</Link>
        </li>
    );
};

export function MenuComponent(){

    const menu = [
        {
            title: 'Check-in feed',
            to: '/app/feed/'
        },
        {
            title: 'Payments',
            to: '/app/payments/'
        },
        {
            title: 'Members',
            to: '/app/members/'
        },
        {
            title: 'Documents',
            to: '/app/documents/'
        },
        // {
        //     title: 'Stats',
        //     to: '/app/stats/'
        // },
        // {
        //     title: 'Gym info',
        //     to: '/app/gym-info/'
        // },
        // {
        //     title: 'Manage admins',
        //     to: '/app/manage-admins/'
        // },
        // {
        //     title: 'Membership levels',
        //     to: '/app/membership-levels/'
        // },
        {
            title: 'Setup device',
            to: '/app/setup-device/'
        },
        // {
        //     title: 'Notifications',
        //     to: '/app/notifications/'
        // }
    ];

    return (
        <>
            <div className="main__menu">
                <ul className="main__menu__links">
                    {menu.map((item, idx) => <MenuItem key={idx} item={item} />)}
                </ul>
            </div>
        </>
    );
}

export default MenuComponent;
