import React from 'react';
import { SEO } from '@components/SEO';
import { MenuComponent } from '@components/Menu';

const StatusComponent = (props) => {
    let { label, status } = props;
    return (
        <div className={`user__status user__status__${status}`}>
            <div className="status__label">{label}</div>
            <div className="status__key"></div>
        </div>
    );
};

const DummyComponent = (props) => {
    let { title } = props;
    return (
        <div className="component">
            <h2 className="component__header">{title}</h2>
            <div className="component__body">
                <div className="data__table">
                    <div className="table__row table__header">
                        <div className="table__column column__date">Date/Time</div>
                        <div className="table__column column__name">Name</div>
                        <div className="table__column">Status</div>
                    </div>
                    <div className="table__row">
                        <div className="table__column column__date">xx/xx/xxxx @ 12:45</div>
                        <div className="table__column column__name">Chris Dykes</div>
                        <div className="table__column"><StatusComponent label="New User" status="amber" /></div>
                    </div>
                    <div className="table__row">
                        <div className="table__column column__date">xx/xx/xxxx @ 12:45</div>
                        <div className="table__column column__name">Chris Dykes</div>
                        <div className="table__column"><StatusComponent label="New User" status="amber" /></div>
                    </div>
                    <div className="table__row">
                        <div className="table__column column__date">xx/xx/xxxx @ 12:45</div>
                        <div className="table__column column__name">Chris Dykes</div>
                        <div className="table__column"><StatusComponent label="Expired xx/xx/xxxx" status="amber" /></div>
                    </div>
                    <div className="table__row">
                        <div className="table__column column__date">xx/xx/xxxx @ 12:45</div>
                        <div className="table__column column__name">Chris Dykes</div>
                        <div className="table__column"><StatusComponent label="Expired xx/xx/xxxx" status="red" /></div>
                    </div>
                    <div className="table__row">
                        <div className="table__column column__date">xx/xx/xxxx @ 12:45</div>
                        <div className="table__column column__name">Chris Dykes</div>
                        <div className="table__column"><StatusComponent label="Expired xx/xx/xxxx" status="green" /></div>
                    </div>
                    <div className="table__row">
                        <div className="table__column column__date">xx/xx/xxxx @ 12:45</div>
                        <div className="table__column column__name">Chris Dykes</div>
                        <div className="table__column"><StatusComponent label="Expired xx/xx/xxxx" status="red" /></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function UserPage(){

    // useEffect(() => {
    //     console.log('this runs on mount!');
    //     let socket = socketIOClient('http://127.0.0.1:3002/');
    //     socket.on('test', (data) => {
    //         console.log('getting mesages from API: ', data);
    //     })
    // }, []);

    return (
        <>
            <SEO title="Home" />
            <div className="main__layout">
                <div className="left__column">
                    <MenuComponent />
                </div>
                <div className="middle__column">

                    <div className="component__wrapper">
                        <h1 className="component__wrapper__header">Chris Dykes</h1>
                        <div className="component__container">
                            <DummyComponent title="IOU's" />
                        </div>
                    </div>

                    <div className="component__wrapper">
                        <div className="component__container">
                            <DummyComponent title="History" />
                        </div>
                    </div>
                </div>
                <div className="right__column">x</div>
            </div>
        </>
    );
};

export default UserPage;
